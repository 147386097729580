import React from "react";
import {Link} from "react-router-dom";

function Navbar(props) {
    return (<nav className="px-2 sm:px-4 py-2.5">
        <div className="container flex flex-wrap justify-between items-center mx-auto px-4 py-1">
            <div className="container-fluid flex justify-start items-center">
                <img src={props.logo} className="w-auto h-6 mr-2"/>
                <Link to={props.url} style={{display: "block"}}> <span
                    className="text-2xl text-white">{props.name}</span></Link>
            </div>

            <button data-collapse-toggle="navbar-default" type="button"
                    className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 md:hidden focus:outline-none"
                    aria-controls="navbar-default" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                          clip-rule="evenodd"></path>
                </svg>
            </button>

            {/*Website navigation*/}
            {props.showWebsiteNav === "yes" ? (
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-base md:font-medium">
                        {props.name !== "Ravenverse" ? (
                            <li>
                                <Link to="/ravenverse"><p
                                    className="block py-2 pr-4 pl-3 cursor-pointer text-azure border-b md:border-0 md:hover:text-white md:p-0">
                                    Ravenverse</p>
                                </Link>
                            </li>
                        ) : (<div/>)}
                        <li>
                            <span
                                className="block py-2 pr-4 pl-3 cursor-pointer text-azure border-b md:border-0 md:hover:text-white md:p-0"
                                onClick={() => {
                                    window.open("https://coinmarketcap.com/currencies/raven-protocol/", "_blank")
                                }}>Raven Token</span>
                        </li>
                        <li><span
                            className="block py-2 pr-4 pl-3 cursor-pointer text-azure border-b md:border-0 md:hover:text-white md:p-0"
                            onClick={() => {
                                window.open("https://steak.ravenprotocol.com/", "_blank")
                            }}>Proof of Steak</span>
                        </li>
                        <li><span
                            className="block py-2 pr-4 pl-3 cursor-pointer text-azure border-b md:border-0 md:hover:text-white md:p-0"
                            onClick={() => {
                                window.open("https://medium.com/ravenprotocol", "_blank")
                            }}>Blog</span>
                        </li>
                        <li><span
                            className="block py-2 pr-4 pl-3 cursor-pointer text-azure border-b md:border-0 md:hover:text-white md:p-0"
                            onClick={() => {
                                window.open("mailto:founders@ravenprotocol.com", "_self")
                            }}>Contact us</span>
                        </li>
                    </ul>
                </div>
            ) : (<></>)}
        </div>
    </nav>)
}

Navbar.defaultProps = {
    name: "Raven Protocol",
    showProfileNav: "yes",
    showWebsiteNav: "no"
}

export default Navbar;