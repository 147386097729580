import React, {useEffect} from "react";
import "./style.css";
import Slider from "./Slider";
import CustomButton from "../CustomButton";

function Announcements() {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [avatar, setAvatar] = React.useState("")
    const [profileLink, setProfileLink] = React.useState("")
    const [posts, setPosts] = React.useState([])
    const [postsFetched, setPostsFetched] = React.useState(false)
    const [slicedPosts, setSlicedPosts] = React.useState([])

    function handleClick() {
        //console.log("handleClick")
        if (isExpanded) {
            setSlicedPosts(posts.slice(1, 4))
            setIsExpanded(false)
        } else {
            setSlicedPosts(posts.slice(1, 10))
            setIsExpanded(true)
        }
    }

    useEffect(() => {
        if (!postsFetched) {
            fetchPosts()
        }
    })

    function fetchPosts() {
        //console.log("fetchPosts")
        let mediumURL = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ravenprotocol';
        fetch(mediumURL)
            .then(res => res.json())
            .then(data => {
                // create two-dimensional array with 3 elements per inner array
                const avatar = data.feed.image;
                const profileLink = data.feed.link;
                const posts = data.items.filter(item => item.categories.length > 0);
                setAvatar(avatar)
                setProfileLink(profileLink)
                setPosts(posts)
                setSlicedPosts(posts.slice(1, 4))
                setIsExpanded(false)
                setPostsFetched(true)
            });
    }

    return (
        <div>
            <div className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0">
                <div
                    className="text-left text-2xl lg:text-4xl md:text-4xl text-left font-semibold leading-none mb-10 lg:mb-20 md:mb-20">
                    Announcements
                </div>

                <div className="gap-10 pb-8 md:pb-28 lg:pb-28">
                    <Slider posts={slicedPosts}/>
                    <div className="h-4 md:h-12 lg:h-12"/>
                    <div className="flex justify-center">
                        <CustomButton text={isExpanded ? "See less" : "See more"} onClick={handleClick}/>
                    </div>
                </div>
                <div className="w-full border-b border-1 my-4 border-white opacity-20"/>
            </div>
        </div>
    );
}

export default Announcements;
