import React from 'react'
import HackerNoon from '../../assets/hackernoon.png'
import CryptoCalibur from '../../assets/crypto-calibur.png'
import CryptoDiffer from '../../assets/crypto-differ.png'
import Forbes from '../../assets/forbes.png'

function Featured() {
    return (

        <div style={{
            backgroundImage: "linear-gradient(195deg, #0c0f2f 60%, #1f1139 10%)",
        }}>
            <div className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0">
                <div
                    className="text-left text-2xl lg:text-4xl md:text-4xl text-left font-semibold leading-none mb-10 lg:mb-20 md:mb-20">
                    Featured
                </div>

                <div className="pb-8 md:pb-24 lg:pb-24 lg:px-4 md:px-4">
                    <div
                        className='lg:flex lg:justify-start md:flex md:justify-start gap-6 overflow-x-auto'>
                        <img src={HackerNoon} alt="hackernoon logo"
                             className="mb-4 mx-auto md:mx-0 lg:mx-0 cursor-pointer"
                             onClick={() => window.open("https://hackernoon.com/t-mobiles-accelerator-and-innovation-lab-is-paving-the-way-for-5g-adoption-across-the-us-bm1t3zdj", "_blank")}/>
                        <img src={CryptoCalibur} alt="crypto calibur logo"
                             className="mb-4 mx-auto md:mx-0 lg:mx-0 cursor-pointer"
                             onClick={() => window.open("https://cryptocalibur.com/portfolio-item/raven-protocol-review/", "_blank")}/>
                        <img src={CryptoDiffer} alt="crypto differ logo"
                             className="mb-4 mx-auto md:mx-0 lg:mx-0 cursor-pointer"
                             onClick={() => window.open("https://medium.com/ravenprotocol/raven-protocol-ama-with-cryptodiffer-24th-january-2020-260466e9643f", "_blank")}/>
                        <img src={Forbes} alt="forbes logo" className="mb-4 mx-auto md:mx-0 lg:mx-0 cursor-pointer"
                             onClick={() => window.open("https://www.forbes.com/sites/shermanlee/2018/07/31/privacy-revolution-how-blockchain-is-reshaping-our-economy/", "_blank")}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Featured 