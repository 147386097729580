import React, {useEffect, useState} from "react";
import Arrow from "../assets/arrow-right-white.svg";

function CustomButton(props) {
    const [classes, setClasses] = useState("flex justify-start items-center bg-gradient-to-r from-mediumDarkCyanBlue to-darkBlueMagenta py-2 px-4 md:py-3 md:px-6 lg:py-3 lg:px-6 text-white text-sm cursor-pointer rounded-lg align-middle my-4 lg:my-0 md:my-0")

    useEffect(() => {
        if (props.putBackground === "true") {
            setClasses("flex justify-start items-center bg-gradient-to-r from-mediumDarkCyanBlue to-darkBlueMagenta py-2 px-4 md:py-3 md:px-6 lg:py-3 lg:px-6 text-white text-sm cursor-pointer rounded-lg align-middle my-4 lg:my-0 md:my-0")
        } else {
            setClasses("flex justify-start items-center py-2 px-4 md:py-3 md:px-6 lg:py-3 lg:px-6 text-white text-sm cursor-pointer rounded-lg align-middle border border-rhino my-4 lg:my-0 md:my-0")
        }
    })

    return (
        <button
            className={classes}
            onClick={props.onClick}>
            <span className="text-sm mr-4 md:text-base lg:text-base">{props.text}</span>
            {props.arrow === "true" ? (
                <img src={Arrow} alt="Arrow"/>
            ) : (<div/>)}
        </button>
    );
}

CustomButton.defaultProps = {
    arrow: "false",
    putBackground: "true"
}

export default CustomButton;