import React from "react";
import Metaverse from "../../assets/metaverse.png";
import Gaming from "../../assets/gaming.png";
import ArtificialLogo from "../../assets/artificial-inteligent.png";
import Web3Logo from "../../assets/web3-icon.svg";
import ApplicationBox from "./component";

function Applications() {
    return (
        <div id="applications"
            className=""
            style={{
                backgroundImage: "linear-gradient(195deg, #0c0f2f 60%, #1f1139 10%)",
            }}
        >
            <div className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0">
                <div
                    className="text-left text-2xl lg:text-4xl md:text-4xl text-left font-semibold leading-none mb-10 lg:mb-20 md:mb-20">
                    Applications
                </div>

                <div className="md:grid md:grid-cols-4 lg:grid lg:grid-cols-4 gap-6 pb-8 md:pb-10 lg:pb-10">
                    <ApplicationBox
                        heading={"Artificial intelligence"}
                        image={ArtificialLogo}
                        content={
                            "Build and train large scale machine learning/deep learning models"
                        }
                    />
                    <ApplicationBox
                        heading={"Web3"}
                        image={Web3Logo}
                        content={
                            "Build Web3 applications(dApps) on top of the Ravenverse"
                        }
                    />
                    <ApplicationBox
                        heading={"Metaverse"}
                        image={Metaverse}
                        content={
                            "Build Metaverse applications on top of the Ravenverse"
                        }
                    />
                    <ApplicationBox
                        heading={"Gaming"}
                        image={Gaming}
                        content={
                            "Gaming applications can offload heavy computations"
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default Applications;
