import React from 'react';
import MediumCard from './MediumCard';

class Slider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 gap-6">
                {this.props.posts.map((post, index) => {
                    return <MediumCard post={post} key={"key_" + index}/>
                })}
            </div>
        );
    }
}

export default Slider;