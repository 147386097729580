import React from "react";
import Requester from "../../assets/requester.svg"
import Provider from "../../assets/provider.svg"
import Facilitator from "../../assets/facilitator.svg"
import Arrow from "../../assets/arrow-right-white.svg"
import CurveBg from "../../assets/curve-bg.svg"

function Participate() {
    return (
        <div id="participate">
            <div className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0">
                <div
                    className="text-left text-2xl lg:text-4xl md:text-4xl text-left font-semibold leading-none mb-10 lg:mb-20 md:mb-20">
                    Participate
                </div>

                <div className="md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 gap-6 pb-8 md:pb-10 lg:pb-10">
                    <div className="rounded-lg mb-4 lg:mb-0 md:mb-0 relative"
                         style={{backgroundImage: 'linear-gradient(359deg, #1f1139 34%, #0065b0 88%)'}}>
                        <div className="p-6" style={{
                            background: `url(${CurveBg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                            <img src={Requester} alt="Requester icon"/>
                        </div>
                        <div className="text-white pl-8 text-2xl font-medium">Requester</div>
                        <div className="text-base pt-3 pb-3 lg:pb-28 pl-8 pr-8" style={{color: '#b3c2ce'}}>
                            Requester is a person or entity who wants to request some compute
                            power for their decentralized applications.
                        </div>
                        <div className="flex lg:absolute lg:bottom-0 lg:left-0" style={{padding: '30px'}}>
                            <div className="mr-4">Know More</div>
                            <img src={Arrow} alt="Arrow"/>
                        </div>
                    </div>
                    <div className="rounded-lg mb-4 lg:mb-0 md:mb-0 relative"
                         style={{backgroundImage: 'linear-gradient(359deg, #1f1139 34%, #0065b0 88%)'}}>
                        <div className="p-6" style={{
                            background: `url(${CurveBg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                            <img src={Provider} alt="Provider icon"/>
                        </div>
                        <div className="text-white pl-8 text-2xl font-medium">Provider</div>
                        <div className="text-base pt-3 pb-3 pl-8 pr-8" style={{color: '#b3c2ce'}}>
                            A provider is a person/entity that wishes to provide computing resources to
                            support the requester's decentralised apps.
                        </div>
                        <div className="flex lg:absolute lg:bottom-0 lg:left-0" style={{padding: '30px'}} >
                            <div className="mr-4">Know More</div>
                            <img src={Arrow} alt="Arrow"/>
                        </div>
                    </div>

                    <div className="rounded-lg mb-4 lg:mb-0 md:mb-0 relative"
                         style={{backgroundImage: 'linear-gradient(359deg, #1f1139 34%, #0065b0 88%)'}}>
                        <div className="p-6" style={{
                            background: `url(${CurveBg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                            <img src={Facilitator} alt="Facilitator icon"/>
                        </div>
                        <div className="text-white pl-8 text-2xl font-medium">Facilitator</div>
                        <div className="text-base pt-3 pb-3 pl-8 pr-8" style={{color: '#b3c2ce'}}>
                            Facilitator is a platform, website, application, uses our tools like
                            ravop, ravjs, ravpy to empower requesters and providers with no code
                            tools to participate in the network.
                        </div>
                        <div className=" flex lg:absolute lg:bottom-0 lg:left-0" style={{padding: '30px'}}>
                            <div className="mr-4">Know More</div>
                            <img src={Arrow} alt="Arrow"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Participate;
