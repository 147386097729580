import React from 'react'
import About from '../components/About'
import Applications from '../components/Applications'
import LandingHeading from '../components/Landing'
import Participate from '../components/Participate'
import Ravenverse from '../components/Ravenverse'
import WhitePaper from '../components/WhitePaper'
import Announcements from '../components/Announcements'
import "./index.css"
import Featured from '../components/Featured'
import Navbar from "../components/Navbar";
import ravenProtocolLogoWhite from "../assets/ravenprotocol-logo-white.png"
import Footer from "../components/Footer";
import Investors from "../components/Investors";

function WebSite() {
    return (
        <div style={{background: '#0c0f2f', overflowX: 'hidden'}}>
            <Navbar name="Raven Protocol" logo={ravenProtocolLogoWhite} url="/" showWebsiteNav="yes"/>
            <LandingHeading/>
            <Applications/>
            <About/>
            <Ravenverse/>
            <Participate/>
            <WhitePaper/>
            <Announcements/>
            <Featured/>
            {/*<Investors/>*/}
            <Footer/>
        </div>
    )
}

export default WebSite