import React from "react";
import AboutIcon from "../../assets/what-is-raven.svg";
import CustomButton from "../CustomButton";

function About() {
    return (
        <div>
            <div className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0">
                <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 pb-8 md:pg-28  md:pg-28">
                    <div className="text-center items-center">
                        <img src={AboutIcon} alt="About Icon"/>
                    </div>
                    <div className="mt-12">
                        <div className="text-3xl md:text-4xl font-bold">
                            <span className="font-light">What is</span> Raven Protocol?
                        </div>

                        <div className="text-base leading-6 my-9">
                            Raven Protocol is a future-oriented computing protocol that specializes in Decentralized Computing Paradigms on Blockchain.
                            <br/><br/>
                            With the advancement of the Internet, applications like artificial intelligence, Web3, gaming, video production, and
                            VFX require cost-effective and cutting-edge methods.
                            We have billions of internet-connected devices with unused compute power and
                            decentralization has the potential to connect these devices as a powerful computing resource.
                            Distributed computing is a scalable, efficient and reliable approach to solving tasks that
                            would prove tedious and time-consuming to an individual system.
                            <br/><br/>
                            Raven Protocol distributes heavy compute requirements to the ecosystem and bridges the gap between the ever-growing compute power demand and the plethora of idle compute resources across the world. It incentivizes who contribute their compute resources in exchange for Raven tokens.
                            <br/><br/>
                        </div>
                        <CustomButton text="Know More" arrow="true"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
