import React from "react";

function ApplicationBox({heading, content, image}) {
    return (
        <div
            className="rounded-lg p-6 bg-gradient-to-b to-tolopea from-mediumDarkCyanBlue shadow-sm mb-4 lg:mb-0 md:mb-0"
        >
            <div className="flex justify-center items-center">
                <img
                    src={image}
                    style={{height: "199px", width: "274px", objectFit: "contain"}}
                    alt="svg icon"
                />
            </div>
            <div className="mt-16 text-xl font-medium">{heading}</div>
            <div
                className="text-slateGray text-base mt-3 leading-6"
            >
                {content}
            </div>
        </div>
    );
}

export default ApplicationBox;
