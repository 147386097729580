import React from 'react';

export default function MediumCard(props) {
    function ToText(node) {
        let tag = document.createElement("div");
        tag.innerHTML = node;
        node = tag.innerText;
        return node;
    }

    function ShortenText(text, startingPoint, maxLength) {
        return text.length > maxLength
            ? text.slice(startingPoint, maxLength)
            : text;
    }

    var shortMonthName = new Intl.DateTimeFormat('en-US', {
        month: 'short'
    }).format;
    let date = new Date(props.post.pubDate);
    const publishDate = shortMonthName(date) + ' ' + date.getDate() + ',' + ' ' + date.getFullYear();
    return (
        <div className="bg-bunting rounded-lg shadow-lg cursor-pointer mb-4 lg:mb-0 md:mb-0" onClick={() => {
            window.open(props.post.link, "_blank")
        }}>
            <img src={props.post.thumbnail} className="rounded-tl-lg rounded-tr-lg"/>
            <div className="p-4">
                <p className="">{props.post.title}</p>
                <p className="text-sm text-azure opacity-70 mt-4">{publishDate}</p>
            </div>
        </div>
    );
}