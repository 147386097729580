import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import CustomButton from "./CustomButton";
import {IconContext} from "react-icons";
import {BsCircleFill} from "react-icons/bs";
import {AiFillStar, AiOutlineFork} from "react-icons/ai";


export default function LibraryCard(props) {
    const github_url = "https://www.github.com/ravenprotocol/"
    const history = useHistory()
    const [stars, setStars] = useState(0)
    const [forks, setForks] = useState(0)
    const [repoName, setRepoName] = useState("")
    const [repoDescription, setRepoDescription] = useState("")
    const [htmlUrl, setHtmlUrl] = useState("")
    const [language, setLanguage] = useState("")

    useEffect(() => {
        fetchRepoDetails(props.library.repo_name)
    })

    function viewCodebase() {
        window.open(github_url + "" + props.library.repo_name, "_blank")
    }

    function viewDocumentation() {
        window.open(github_url + "" + props.library.repo_name + "/blob/main/README.md", "_blank")
    }

    function fetchRepoDetails(repo) {
        fetch('https://api.github.com/repos/ravenprotocol/' + repo).then(function (response) {
            return response.json();
        }).then(function (response) {
            setForks(response.forks)
            setStars(response.stargazers_count)
            setRepoName(response.name)
            setRepoDescription(response.description)
            setHtmlUrl(response.html_url)
            setLanguage(response.language)
        });
    }

    return (<div
        className="m-4 lg:mt-6 lg:m-0 bg-bunting p-4 md:p-8 lg:p-8 text-white rounded-xl">
        <div className="lg:flex lg:justify-between lg:flex lg:justify-between items-center mb-6">
            <div className="flex justify-start items-center">
                <img src={props.icon} alt="icon"/>
                <h1 className="text-2xl font-medium ml-4 lg:ml-6 md:ml-6">{props.library.name}</h1>
            </div>

            <div className="md:flex md:justify-start lg:flex lg:justify-start gap-8 mt-6 lg:mt-0 md:mt-0">
                <CustomButton text="View codebase" onClick={viewCodebase} arrow="true"/>
                <CustomButton text="View documentation" onClick={viewDocumentation} arrow="true"/>
            </div>
        </div>

        <div className="md:grid gap-4 md:grid-cols-2 lg:grid lg:grid-cols-2 mt-4 mb-4">
            <div className="text-base text-left font-normal mb-8 lg:mb-0 md:mb-0">
                <p>{props.library.description}</p>
                <div className="md:flex md:justify-start lg:flex lg:justify-start gap-4 mt-6 lg:mt-8 md:mt-8">
                    <p className="text-lg underline">Github:</p>
                    <div className="flex justify-start items-center gap-6 text-azure opacity-70">
                        <IconContext.Provider
                            value={{color: 'black'}}>
                            <div className="flex justify-start items-center">
                                <BsCircleFill/>
                                <span className="ml-2">{language}</span>
                            </div>
                        </IconContext.Provider>

                        <IconContext.Provider
                            value={{color: 'black'}}>
                            <div className="flex justify-start items-center">
                                <AiFillStar/>
                                <span className="ml-2">{stars}</span>
                            </div>
                        </IconContext.Provider>

                        <IconContext.Provider
                            value={{color: 'black'}}>
                            <div className="flex justify-start items-center">
                                <AiOutlineFork/>
                                <span className="ml-2">{forks}</span>
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>

                <p className="text-lg break-words mt-8">View full documentation at: <a
                    className="underline text-base text-azure opacity-70 break-words"
                    href="https://github.com/ravenprotocol/ravop/blob/main/README.md" target="_blank">
                    {props.library.documentation}</a></p>
            </div>

            <div className="border-l border-gray-600 p-4">
                <h1 className="text-2xl font-medium">How to use {props.library.name}</h1>

                <div className="mt-4 mb-8">
                    {props.library.steps.map((step, index) => {
                        return <div key={"key_"+index}>
                            <p className="text-lg mb-2">
                                {step.title}
                            </p>
                            <p className="text-black break-words mb-2 bg-white rounded-lg text-black p-3 mb-4">
                                {step.code}
                            </p>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>)
}