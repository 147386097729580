import React from "react";
import Ravpy from "../../assets/ravpy-icon.svg";
import Ravjs from "../../assets/ravjs.svg";
import Ravsock from "../../assets/ravsock.svg";
import Ravdl from "../../assets/ravdl.svg";
import Ravop from "../../assets/ravop.svg";
import Arrow from "../../assets/arrow-right-white.svg";

function Ravenverse() {
    return (
        <>
            <div style={{
                backgroundImage: "linear-gradient(195deg, #0c0f2f 60%, #1f1139 10%)",
            }}>
                <div
                    className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0">
                    <div
                        className="lg:grid lg:grid-cols-3 lg:grid-rows-2 md:grid md:grid-cols-3 md:grid-rows-2 gap-6 pb-8 md:pb-10 lg:pb-10">
                        <div className="flex items-center">
                            <div>
                                <div className="text-4xl text-left font-light">
                                    What is <span className="font-medium">Ravenverse?</span>
                                </div>
                                <div
                                    className="lg:mr-8 lg:mb-28 mt-5 mb-10 text-base text-blueBell text-left font-normal leading-6"
                                >
                                    Ravenverse is a decentralized computing framework to power
                                    decentralized applications in the Metaverse
                                </div>
                            </div>
                        </div>

                        <div className="bg-bunting rounded-xl p-6">
                            <div className="flex justify-between items-center mb-6 pr-6">
                                <img src={Ravsock} alt="Ravsock icon"/>
                                <img src={Arrow} alt="Arrow"/>
                            </div>
                            <div className="text-left text-2xl mb-5">Ravsock</div>
                            <div className="text-base text-left text-azure opacity-70 leading-6">
                                Ravsock uses a scheduling algorithm to assign ops to the available nodes and coordinates between them.
                                It facilitates the efficient distribution of ops and the efficient merging of results.
                            </div>
                        </div>

                        <div className="bg-bunting rounded-xl p-6 mt-4 lg:mt-0 md:mt-0">
                            <div className="flex justify-between items-center mb-6 pr-6">
                                <img src={Ravop} alt="Ravop icon"/>
                                <img src={Arrow} alt="Arrow"/>
                            </div>
                            <div className="text-left text-2xl mb-5">Ravop</div>
                            <div className="text-base text-left text-azure opacity-70 leading-6">
                                Ravop is one of the crucial build blocks of Ravenverse. It is a library for requesters to create and interact with ops, perform mathematical calculations, and write algorithms.
                            </div>
                        </div>

                        <div className="bg-bunting rounded-xl p-6 mt-4 lg:mt-0 md:mt-0">
                            <div className="flex justify-between items-center mb-6 pr-6">
                                <img src={Ravpy} alt="Ravpy icon"/>
                                <img src={Arrow} alt="Arrow"/>
                            </div>
                            <div className="text-left text-2xl mb-5">Ravpy</div>
                            <div className="text-base text-left text-azure opacity-70 leading-6">
                                Ravpy is a python SDK that allows providers to intuitively participate in any ongoing
                                graph computations in the Ravenverse. It has support for both distributed and federated computing.
                            </div>
                        </div>

                        <div className="bg-bunting rounded-xl p-6 mt-4 lg:mt-0 md:mt-0">
                            <div className="flex justify-between items-center mb-6 pr-6">
                                <img src={Ravjs} alt="Ravjs icon"/>
                                <img src={Arrow} alt="Arrow"/>
                            </div>
                            <div className="text-left text-2xl mb-5">Ravjs</div>
                            <div className="text-base text-left text-azure opacity-70 leading-6">
                                A Javascript SDK to participate ongoing graph computations in the Ravenverse.
                                Ravjs comes with it’s own UI and there is no need to fiddle with the integration.
                            </div>
                        </div>

                        <div className="bg-bunting rounded-xl p-6 mt-4 lg:mt-0 md:mt-0">
                            <div className="flex justify-between items-center mb-6 pr-6">
                                <img src={Ravdl} alt="RavDL icon"/>
                                <img src={Arrow} alt="Arrow"/>
                            </div>
                            <div className="text-left text-2xl mb-5">RavDL</div>
                            <div className="text-base text-left text-azure opacity-70 leading-6">
                                RavDL is a high level python wrapper that defines the mathematical backend for building layers of neural networks and to
                                provide essential abstractions for training complex DL architectures in the Ravenverse.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ravenverse;
