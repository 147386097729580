import React from "react";
import WhitePaperIcon from "../../assets/white-paper.svg";
import CustomButton from "../CustomButton";

function WhitePaper() {
    return (
        <div style={{backgroundImage: "linear-gradient(316deg, #070927 64%, #1f1139 19%)"}} className="bg-cover">
            <div
                className="md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto my-10 md:my-20 lg:my-20 m-6 lg:m-0 md:m-0 pt-10">
                <div
                    className="md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 gap-6 pb-8 md:pb-28 md:pb-28 flex flex-col-reverse">

                    <div className="mb-4 lg:mb-0 md:mb-0">
                        <div
                            className="text-left text-2xl lg:text-4xl md:text-4xl text-left font-semibold leading-none mb-4 lg:mb-8 md:mb-8">
                            Whitepaper 2.0
                        </div>

                        <div className="text-base mb-10">
                        </div>

                        <div className="text-lg">
                            Coming soon...
                        </div>

                        <div
                            className="text-left text-2xl lg:text-4xl md:text-4xl text-left font-semibold leading-none
                            mb-4 lg:mb-8 md:mb-8 mt-8 lg:mt-16 md:mt-16">
                            Whitepaper 1.0
                        </div>

                        <CustomButton text="Read paper" onClick={() => {
                            window.open("https://drive.google.com/file/d/1FAaVKkg_CjxMj-n1yHZc6ufcVDtOU1Ct/view", "_blank")
                        }} arrow="true"/>
                    </div>

                    <div className="flex justify-center items-center">
                        <img src={WhitePaperIcon} alt="White Paper Icon"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhitePaper;
