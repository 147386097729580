import Navbar from "../components/Navbar";
import gradientDark from "../assets/gradient-dark.png";
import Arrow from "../assets/arrow-right-white.svg";
import React from "react";
import ravpyLogo from "../assets/ravpy-icon.svg";
import ravdlLogo from "../assets/ravdl.svg";
import ravopIcon from "../assets/ravop.svg";
import whatIsRavenLogo from "../assets/what-is-raven.svg";
import LibraryCard from "../components/LibraryCard";
import libraries from '../libraries.json'
import Footer from "../components/Footer";
import ravenProtocolLogo from "../assets/raven-logo.webp"
import ravenProtocolLogoWhite from "../assets/ravenprotocol-logo-white.png"
import ravenverseLogo from "../assets/ravenverse-logo.png"
import HeaderGraphic from "../assets/header-graphics.png"
import RavenverseEcosystemGraphic from "../assets/ravenverse-ecosystem.svg";


export default function Ravenverse() {
    function launchApp() {
        window.open("https://www.ravenverse.ai", "_blank")
    }

    return (<div>
        <Navbar name="Raven Protocol" logo={ravenProtocolLogoWhite} url="/" showWebsiteNav="yes"/>

        <div className="bg-cover lg:bg-cover">
            <div
                className="lg:grid lg:grid-cols-2 max-w-7xl mx-auto flex flex-col-reverse pt-4 lg:pt-10 md:flex-row mb-8 justify-around items-center"
                style={{minHeight: "70vh"}}>
                <div
                    className="text-center lg:text-left md:text-left p-5 lg:p-0 flex flex-col justify-center items-center lg:items-start">

                    <div className="text-2xl lg:text-4xl" style={{lineHeight: "1.25em"}}>
                        <div className="flex justify-center lg:justify-start items-center mb-12 mt-8 text-black">
                            <img src={ravenverseLogo} alt="Ravenverse logo" className="w-16 h-auto mr-4"/>
                            <span className="text-3xl lg:text-5xl">Ravenverse</span>
                        </div>
                        <p>
                            <span className="mr-4 text-2xl lg:text-4xl">
                                <span className="font-bold">A Decentralized Computing</span>
                                <br/>
                                <span className="text-azure opacity-70">backend for Artificial Intelligence, <br/>Web3, Metaverse and Gaming apps</span>
                            </span>
                        </p>
                    </div>

                    <div className="m-4 lg:m-10 lg:ml-0 text-xl font-medium">
                        <span className="text-azure opacity-70">
                            Build dApps Now
                        </span>
                    </div>

                    <button
                        className="flex justify-start items-center bg-gradient-to-r mt-8 md:mt-14 lg:mt-14 from-mediumDarkCyanBlue to-darkBlueMagenta py-4 px-8 text-white text-sm cursor-pointer rounded-lg align-middle"
                        onClick={launchApp}>
                        <span className="text-base mr-11">Launch App</span>
                        <img src={Arrow}/>
                    </button>
                </div>

                <div className="m-8 lg:m-16 md:m-16 text-center lg:order-last">
                    <img src={HeaderGraphic} alt="landing image"/>
                </div>
            </div>

            <div className="w-9/12 mx-auto border-b border-1 my-4 border-white opacity-30"/>

        </div>

        <div className="max-w-7xl mx-auto mb-8 mt-8 lg:mt-16 md:lg-16">
            <div className="text-4xl text-center md:text-left mb-10 lg:mb-10 md:mb-10 font-semibold leading-none">
                Ecosystem
            </div>

            <LibraryCard library={libraries.ravop} icon={ravopIcon}/>
            <LibraryCard library={libraries.ravpy} icon={ravpyLogo}/>
            <LibraryCard library={libraries.ravdl} icon={ravdlLogo}/>
        </div>

        <div
            className="relative text-center m-4 md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto mt-4 md:mt-8 lg:mt-8 bg-bunting p-4 md:p-8 lg:p-8 text-white rounded-xl">
            <p className="text-2xl text-center">Join us</p>
            <div className="text-center mt-4 mb-4 text-base text-azure opacity-70">
                Help us build the best decentralized computing backend for the future.
            </div>

            <button
                onClick={() => {
                    window.open("https://angel.co/company/raven-protocol/jobs", "_blank")
                }}
                className="bg-gradient-to-r from-mediumDarkCyanBlue to-darkBlueMagenta pt-2 pb-2 pl-6 pr-6 text-white text-sm rounded align-middle">
                <span className="text-base">Join Now</span>
            </button>
        </div>

        <Footer/>
    </div>)
}