import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./styles/App.css";
import WebSite from "./Pages";
import Ravenverse from "./Ravenverse";

export default function App() {
    return (
        <div className="app">
            <Router>
                <Switch>
                    <Route exact path="/" component={WebSite} />
                    <Route exact path="/ravenverse" component={Ravenverse} />
                </Switch>
            </Router>
        </div>
    );
}
