import React from "react";
import Arrow from "../../assets/arrow-right-white.svg";
import gradientDark from "../../assets/gradient-dark.png";
import {useHistory} from "react-router-dom";
import RavenverseEcosystemGraphic from "../../assets/ravenverse-ecosystem.svg"


function LandingHeading() {
    const history = useHistory()

    return (
        <div className="bg-cover lg:bg-cover invert-0">
            <div className="max-w-7xl mx-auto">
                <div
                    className="lg:grid lg:grid-cols-2 lg:grid-re pt-10 mb-8 justify-around items-center"
                    style={{minHeight: "70vh"}}>
                    <div className="m-12 lg:m-16 md:m-16 text-center lg:order-last">
                        <img src={RavenverseEcosystemGraphic} alt="landing image"/>
                    </div>
                    <div
                        className="text-center lg:text-left md:text-left p-3 lg:p-5 md:p-5 lg:p-0 flex flex-col justify-center items-center lg:items-start">

                        <div className="text-4xl lg:text-5xl md:text-5xl" style={{lineHeight: "1.25em"}}>
                            <p><span className="font-bold">Decentralized</span> Computing </p>
                            <p>Protocol for the
                                <span className="font-bold"> Future</span>
                            </p>
                        </div>

                        <div className="mt-10 lg:mt-16 md:mt-16 mr-6 mt-4 lg:mt-8 md:mt-8 ml-1 text-2xl font-medium">
                            <span><span className="font-light">Build</span> Artificial Intelligence, Web3, Metaverse, and Gaming</span>
                            <span className="font-light"> dApps on Ravenverse</span>
                        </div>

                        <button
                            onClick={() => {
                                history.push("/ravenverse")
                            }}
                            className="flex justify-start items-center bg-gradient-to-r mt-14 from-mediumDarkCyanBlue to-darkBlueMagenta py-4 px-8 text-white text-sm cursor-pointer rounded-lg align-middle">
                            <span className="text-base mr-11">Ravenverse</span>
                            <img src={Arrow}/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-9/12 mx-auto border-b border-1 my-4 border-white opacity-30"/>
        </div>
    );
}

export default LandingHeading;
