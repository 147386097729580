import ravenProtocolLogo from '../assets/raven-logo.webp'
import ravenverseLogo from '../assets/ravenverse-logo.png'
import facebookLogo from '../assets/facebook-logo.svg'
import twitterLogo from '../assets/twitter-logo.svg'
import mediumLogo from '../assets/medium-logo.svg'
import discordLogo from '../assets/discord-logo.svg'
import telegramLogo from '../assets/telegram-logo.svg'
import React from "react";

export default function Footer(props) {
    return (<div className="m-4 md:max-w-7xl md:mx-auto lg:max-w-7xl lg:mx-auto mt-4 rounded-lg">
        <div className="md:grid md:grid-cols-4 lg:grid lg:grid-cols-4">
            <div className="col-span-2 text-center md:text-start lg:text-start mt-4">
                <div className="text-xl mb-4 flex justify-center md:justify-start">
                    <img src={ravenProtocolLogo} alt="ravenverse logo" className="w-auto h-8 mr-4"/>
                    Raven Protocol Pvt Ltd
                </div>
                <div className="text-xl mb-4 flex flex justify-center md:justify-start hidden">
                    <img src={ravenverseLogo} alt="ravenverse logo" className="w-auto h-6 mr-4 ml-2"/>
                    Ravenverse
                </div>

                <div className="flex justify-center md:justify-start lg:justify-start gap-4 mt-12 ml-2">
                    <img src={twitterLogo} className="w-8 h-auto cursor-pointer" alt="Twitter logo" onClick={()=>{window.open("https://twitter.com/raven_protocol", "_blank")}}/>
                    <img src={facebookLogo} className="w-8 h-auto cursor-pointer" alt="Facebook logo" onClick={()=>{window.open("https://www.facebook.com/RavenProtocol", "_blank")}}/>
                    <img src={discordLogo} className="w-8 h-auto cursor-pointer" alt="Discord logo" onClick={()=>{window.open("https://discord.gg/kWHMErWh", "_blank")}}/>
                    <img src={mediumLogo} className="w-8 h-auto cursor-pointer" alt="Medium logo" onClick={()=>{window.open("https://medium.com/ravenprotocol", "_blank")}}/>
                    <img src={telegramLogo} className="w-8 h-auto cursor-pointer" alt="Telegram logo" onClick={()=>{window.open("https://t.me/ravenprotocol", "_blank")}}/>
                </div>
            </div>

            <div className="mt-8 lg:mt-0 md:mt-0 text-center md:text-left lg:text-left">
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("/ravenverse", "_self")}}>Ravenverse</p>
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("https://coinmarketcap.com/currencies/raven-protocol/", "_blank")}}>Raven Token</p>
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("https://medium.com/ravenprotocol", "_blank")}}>Blog</p>
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("mailto:founders@ravenprotocol.com", "_self")}}>Contact Us</p>
            </div>

            <div className="lg:mt-0 md:mt-0 text-center md:text-left lg:text-left">
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("/#participate", "_self")}}>Requester</p>
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("/#participate", "_self")}}>Provider</p>
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("/#participate", "_self")}}>Facilitator</p>
                <p className="mb-2 cursor-pointer" onClick={()=>{window.open("/#participate", "_self")}}>Participate</p>
            </div>
        </div>

        <div className="w-full border-b border-1 my-4 border-white opacity-20"/>

        <div className="md:flex md:justify-between lg:flex lg:justify-between">
            <p className="text-azure opacity-70">Copyright © 2022 Raven Protocol Ltd. All rights reserved.</p>
            <div className="flex gap-8 text-azure opacity-70">
                <span>
                    Privacy Policy
                </span>
                <span>
                    Terms of Service
                </span>
            </div>
        </div>
    </div>)
}